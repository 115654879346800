import { render, staticRenderFns } from "./OrdersCompletion.vue?vue&type=template&id=648058e7"
import script from "./OrdersCompletion.vue?vue&type=script&lang=js"
export * from "./OrdersCompletion.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports